<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="'巴林右旗疫苗库存明细'" @selectJob="selectJob" />

    <div class="content_box w_full h_full-50 flex_row_around p_30">
      <!-- 仓库 -->
      <div class="w_30_p h_full ph_5">
        <div class="full flex_column_between pv_40 pl_20 border_box chart_box_bgi_2 relative" @mouseleave="activeIndex = null">
          <!-- 防疫员 仓库 -->
          <div 
            class="w_40_p h_full absolute top_0 right_0 overflow_y_auto scroll_box border_1_s_002B67 chart_box_bgi_2 z_99999" 
            v-show="activeIndex !== null"
            @mouseleave="activeIndex = null"
          >
            <div class="w_full h_40 lh_40 text_1 fw_bold title_bgi1 bgs_full color_fff text_center">
              防疫员列表
            </div>
            <div 
              class="text_1 pv_10 cursor color_fff text_center"
              v-for="(item2, index2) in storeList2" :key="index2"
              @click="handleClickStore(item2.id, item2.name, jobId, item2.level, item2.managerId)"
            >
              · <span class="ml_5"></span>{{ item2.name }}
            </div>
          </div>

          <!-- 动防站, 仓库 -->
          <div 
            class="w_full cursor flex_start" 
            v-for="(item, index) in siteStoreList" :key="index"
            @click="handleClickStore(item.id, item.name, jobId, item.level, item.managerId)"
          >
            <div 
              class="w_full flex_row_start text_1"
              :class="{ active_color: activeIndex == index }"
              @mouseover="handleMouseoverStoreName(index, item.siteId)"
            >
              <div class="w_40 font_number fw_bold jian_bian_text_3 hover_color_fff">{{ index + 1 }}.</div> 
              <div 
                class="w_full fw_500 ml_5 color_A1C6F2 hover_color_fff ls_3 border_b_1_s_001531"
                :class="{ 
                  'color_fff_i': activeStoreId == item.id, 
                  'border_b_1_s_002B67': activeIndex == index
                }"
              >
                {{ formatSiteName(item.name) }}
              </div> 
            </div>
          </div>
        </div> 
      </div>

      <!-- 疫苗 -->
      <div class="w_70_p ph_5 chart_box_bgi_2">
        <div class="w_full h_40 lh_40 flex_row_between">
          <div class="w_30_p jian_bian_text_1 text_1 fw_bold" @click="clickStoreName()">
            疫苗仓库: {{  activeStoreName }}
          </div>  

          <div class="w_30_p h_40 lh_40 flex_row_start">
            <div class="flex_1 text_right cursor" :class="[isShowTable ? 'main_color': 'color_fff']" @click="showTable()">表格</div>
            <div class="flex_1 text_right cursor" :class="[isShowChart ? 'main_color': 'color_fff']" @click="showChart()">图表</div>
          </div>

          <div class="w_10_p h_40 lh_40">
            <el-button type="success" size="mini" @click="exportInventoryData">导出数据</el-button> 
          </div>
        </div>
        
        <div class="w_full h_full-40">
          <div class="full" v-if="isShowTable">
            <el-table :data="inventoryList" border style="width: 100%; height:100%" max-height="800">
              <el-table-column prop="goodsName" label="疫苗名称" align="center" />
              <el-table-column prop="goodsMaker" label="厂家" align="center" />
              <el-table-column prop="goodsSpecs" label="规格" align="center" />
              <el-table-column prop="batchNumber" label="批号" align="center" />
              <el-table-column prop="temperature" label="存放温度" align="center" />
              <el-table-column prop="quantity" label="库存" align="center" />
              <el-table-column prop="" label="明细" align="center" >
                <template slot-scope="scope">
                  <el-button type="warning" size="mini" @click="clickMingXi(scope.row.goodsId, scope.row.goodsName)">
                    明细
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="full" v-if="isShowChart">
            <yi-miao-chart
              :chartData="vaccinumCountChart" 
              :isHideFullIcon="true"
              @clickYiMiao="clickYiMiao"
            />
          </div>
        </div>
      </div>

      <!-- 疫苗进出明细 -->
      <el-dialog
        :title="activeVaccinumName + '疫苗进出明细'"
        :visible.sync="isShowChangeLog"
        width="80%"
      >
        <el-button type="success" size="mini" @click="exportMingXiData">导出明细</el-button> 
        <el-table
          v-if="isShowChangeLog"
          :data="changelogList"
          style="width: 100%"
          size="small"
          max-height="400"
        >
          <el-table-column prop="createTime"  label="时间" width="180" />
          <el-table-column 
            label="类型" width="120" 
            :filters="filterTagList[level]"
            :filter-method="filterChangeType"
            filter-placement="bottom-end"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.changeType == 0" type="primary">入库</el-tag>
              <el-tag v-else-if="scope.row.changeType == 1" type="success">领取</el-tag>
              <el-tag v-else-if="scope.row.changeType == 2" type="info">接收</el-tag>
              <el-tag v-else-if="scope.row.changeType == 3" type="danger">防疫记录作废</el-tag>
              <el-tag v-else-if="scope.row.changeType == 4" type="danger">用量修正+</el-tag>
              <el-tag v-else-if="scope.row.changeType == -1" type="warning">发放</el-tag>
              <el-tag v-else-if="scope.row.changeType == -2" type="danger">退还</el-tag>
              <el-tag v-else-if="scope.row.changeType == -3" type="primary">防疫</el-tag>
              <el-tag v-else-if="scope.row.changeType == -4" type="danger">用量修正-</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="changeNum" label="数量" width="150" align="right">
            <template slot-scope="scope">
              <div class="w_full">
                <span class="color_ff2c64" v-if="scope.row.changeType < 0">- {{ scope.row.changeNum }}</span>
                <span class="color_000" v-else>+ {{ scope.row.changeNum }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="最终库存" width="150" align="right" />
          <el-table-column width="20" />
          <el-table-column prop="remark" label="备注" align="left" />
        </el-table>

        <!-- 分页 -->
        <div class="w_full h_35 text_right p_10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            :page-sizes="[20, 50, 100, 200]"
            layout="total, sizes, prev, pager, next"
            :total="changeLogPaginationParams.total">
          </el-pagination>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="isShowChangeLog = false">关闭</el-button>
        </span>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

import defMixin from '@/mixins/def.js'
import componentsMixin from '@/mixins/components.js'
import dataChartMixin from '@/mixins/data-charts.js'

import { 
  getInventory,
  getStoreList,
  getInventoryChangeLog
} from '@/api/fang-yi/yi-miao.js'

export default {
  name: 'StorePage',
  mixins: [defMixin, componentsMixin, dataChartMixin],
  components: { },
  data() {
    return {
      activeIndex: null,

      activeSiteName: null,

      isPersonalStore: false,

      activeStoreId: 1,
      activeStoreName: null,
      level: 2,

      activeVaccinumId: null,
      activeVaccinumName: null,
      managerId: null,

      jobId: null,
      jobName: JSON.parse(sessionStorage.job).jobName,
      jobList: [],

      vaccinumCountChart: {
        unit: '毫升、头份',
        isShowUnit: true,
        data: []
      },
      storeList: [],
      storeList1: [],
      storeList2: [],
      siteStoreList: [],
      personalStoreList: [],

      isShowChangeLog: false,
      changelogList: [],

      changeLogPaginationParams: {
        total: 0,
        page: 1,
        size: 20,
      },

      inventoryList: [],
      isShowChart: false,
      isShowTable: true,

      filterTagList: [
        [
          { text: '领取', value: '1' },
          { text: '退还', value: '-2' },
          { text: '防疫记录作废', value: '3' },
          { text: '防疫', value: '-3' },
          { text: '用量修正+', value: '4' },
          { text: '用量修正-', value: '-4' },
        ],
        [
          { text: '领取', value: '1' },
          { text: '发放', value: '-1' },
          { text: '接收', value: '2' },
          { text: '退还', value: '-2' },
        ],
        [
          { text: '入库', value: '0' },
          { text: '发放', value: '-1' },
          { text: '接收', value: '2' },
        ]
      ]
    };
  },
  async mounted() {
    // 获取仓库列表
    await this.getStoreList()

    // 获取疫苗库存
    await this.getInventoryList(this.siteStoreList[0].id, this.jobId)
  },
  methods: {
    selectJob(jobId) {
      this.jobId = jobId;

      this.getInventoryList(this.activeStoreId, jobId)
    },

    // 获取 仓库列表
    async getStoreList() {
      let res = await getStoreList()
      try {
        if (res.code == 1000) {
          this.storeList = res.data

          let isShowMoNi = window.sessionStorage.getItem('isShowMoNi')
          if (isShowMoNi) {
            this.siteStoreList = res.data.filter(item => item.isPersonal == 0)
          } else {
            this.siteStoreList = res.data.filter(item => item.isPersonal == 0 && item.name !== '模拟防疫站')
          }
          
          this.personalStoreList = res.data.filter(item => item.isPersonal == 1)

          this.activeStoreId = this.siteStoreList[0].id
          this.activeStoreName = this.siteStoreList[0].name
        }
      } catch (err) {
        console.log('获取 仓库失败 : ', err);
      }
    },

    // 显示 动防站下的 防疫员
    handleMouseoverStoreName(index, siteId) {
      this.activeIndex = index
      this.activeSiteName = this.siteStoreList[index].name
      this.storeList2 = this.personalStoreList.filter(item => item.isPersonal == 1 && item.siteId == siteId)
    },

    // 获取 疫苗 库存信息
    async getInventoryList(storeId, jobId) {
      let params = {
        storeId,
        jobId,
        page: 1,
        size: 1000
      }
      let res = await getInventory(params)
      try {
        if (res.code == 1000) {
          this.inventoryList = res.data.list

          let data = []

          res.data.list.map(item => {
            data.push({
              id: item.goodsId,
              name: item.goodsName,
              value: item.quantity
            })
          })

          this.vaccinumCountChart.data = data

          this.vaccinumCountChart = {...this.vaccinumCountChart}
        }
      } catch (err) {
        console.log(err)
      }
    },

    // 获取 疫苗进出 明细
    async getInventoryChangeLogRes(storeId, goodsId) {
      this.isShowChangeLog = true
      let params = {
        storeId,
        goodsId,
        page: this.changeLogPaginationParams.page,
        size: this.changeLogPaginationParams.size
      }
      let res = await getInventoryChangeLog(params)
      try {
        if (res.code == 1000) {
          this.changelogList = res.data.list

          this.changeLogPaginationParams.total = res.data.pagination.total
        }
      } catch (err) {
        console.log(err)
      }
    },

    // 点击 仓库 名称, 获取 仓库中的 疫苗库存信息
    handleClickStore(storeId, storeName, jobId, level, managerId) {
      this.activeStoreId = storeId
      this.level = level
      this.activeStoreName = level == 0 ? this.activeSiteName + '——' + storeName : storeName
      this.managerId = managerId

      this.getInventoryList(storeId, jobId) 
    },

    // 监听 点击 疫苗 (Echart)
    clickYiMiao(params) {
      let vaccinum_id = params.id

      this.inventoryList.map(item => {
        if (item.id == params.id) {
          this.activeVaccinumName = item.goodsName
        }
      })

      // 获取 疫苗 进出明细
      this.getInventoryChangeLogRes(this.activeStoreId, vaccinum_id)
    },

    // 点击 el-table 【明细】按钮， 监听 点击 疫苗 明细 (Table)
    clickMingXi(vaccinum_id, vaccinum_name) {
      this.activeVaccinumId = vaccinum_id
      this.activeVaccinumName = vaccinum_name

      // 获取 疫苗 进出明细
      this.getInventoryChangeLogRes(this.activeStoreId, vaccinum_id)
    },

    // 跳转到 ERP 页面
    clickStoreName() {
      // let path = this.isPersonalStore ? 
      //   `/erpPerson/${this.activeStoreId}/${this.activeStoreName}/${this.managerId}` 
      //   :
      //   `/erpSite/${this.activeStoreId}/${this.activeStoreName}/${this.managerId}`

      // this.$router.push({
      //   path
      // })
    },

    // 筛选
    filterChangeType(value, row) {
      return row.changeType == value
    },

    handlePageChange(val) {
      this.changeLogPaginationParams.page = val

      this.getInventoryChangeLogRes(this.activeStoreId, this.activeVaccinumId)
    },

    handleSizeChange(val) {
      this.changeLogPaginationParams.size = val

      this.getInventoryChangeLogRes(this.activeStoreId, this.activeVaccinumId)
    },

    showTable() {
      this.isShowTable = true
      this.isShowChart = false
    },

    showChart() {
      this.isShowChart = true
      this.isShowTable = false

      this.getInventoryList(this.activeStoreId, this.jobId)
    },

    // 导出疫苗库存数据
    async exportInventoryData() {
      let dataList = this.inventoryList

      let workbook = new Excel.Workbook();
			let worksheet = workbook.addWorksheet('我的工作表');
			worksheet.columns = [
				{ header: '疫苗名称', key: 'goodsName', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
				{ header: '疫苗厂家', key: 'goodsMaker', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
				{ header: '规格', key: 'goodsSpecs', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
				{ header: '批号', key: 'batchNumber', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
				{ header: '存放温度', key: 'temperature', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
				{ header: '库存', key: 'quantity', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
			];
			for (let index in dataList) {
				worksheet.addRow(dataList[index]);
			}
			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			// saveAs(blob, '乡镇总防疫量.xlsx');
			saveAs(blob, `${this.activeStoreName}-${this.jobName}-疫苗库存.xlsx`);
    },

    // 导出 xxx疫苗的 进出明细 （分页 生成 Excel）
    async exportMingXiData() {
      let workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('我的工作表');
      worksheet.columns = [
        { header: '时间', key: 'createTime', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
        { header: '类型', key: 'changeTypeName', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
        { header: '数量', key: 'changeNum', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
        { header: '最终库存', key: 'amount', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
        { header: '备注', key: 'remark', width: 50, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } }
      ];

      let total = this.changeLogPaginationParams.total
      let size = this.changeLogPaginationParams.size

      let page = Math.ceil(total / size)

      for (let i = 0; i < page; i++) {
        let dataList = []

        let params = {
          storeId: this.activeStoreId,
          goodsId: this.activeVaccinumId,
          page: i + 1,
          size: this.changeLogPaginationParams.size
        }

        let res = await getInventoryChangeLog(params)
        try {
          if (res.code == 1000) {
            dataList = res.data.list 

            for (let i in dataList) {
              if (dataList[i].changeType == 0) {
                dataList[i]['changeTypeName'] = '入库'
              } else if (dataList[i].changeType == 1) {
                dataList[i]['changeTypeName'] = '领取'
              } else if (dataList[i].changeType == 2) {
                dataList[i]['changeTypeName'] = '退还'
              } else if (dataList[i].changeType == -1) {
                dataList[i]['changeTypeName'] = '调拨'  
              } else if (dataList[i].changeType == -2) {
                dataList[i]['changeTypeName'] = '返还'  
              } else {
                dataList[i]['changeTypeName'] = '其他'
              }

              worksheet.addRow(dataList[i]);
            }
          }

          // excel 生成完成后
          // saveAs(blob, `${this.activeStoreName}-${activeVaccinumName}-疫苗进出明细.xlsx`);
        } catch (err) {
          console.log('获取疫苗进出明细失败', err);
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${this.activeStoreName}-${this.activeVaccinumName}-${this.jobName}-疫苗进出明细.xlsx`);
    }
  }
}
</script>